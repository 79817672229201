<template>
  <div>
    <div class="detail-div mx-auto shadow p-5 my-5 bg-white rounded table-responsive" style="height: 500px;" v-if="orderInfo === null">
      &nbsp;
    </div>
    <div class="detail-div mx-auto shadow p-5 my-5 bg-white rounded table-responsive" v-else>
      <v-btn class="my-3" text @click="$router.push('/orders');">
        <i class="flaticon2-back"></i>
        <span class="mx-5 font-bold font-20">Order Detail</span>
      </v-btn>
      <div class="black-line" />
      <div class="m-3 d-flex justify-content-between">
        <span>Type</span>
        <span class="font-bold font-20">{{ orderInfo.type }}</span>
      </div>
      <div v-if="orderInfo.dateOrig">
        <div class="m-3 d-flex justify-content-between">
          <span>Original Date/Time</span>
          <span class="font-bold font-15">{{ orderInfo.dateOrig }}</span>
        </div>
        <div class="m-3 d-flex justify-content-between">
          <span>Rescheduled Date/Time</span>
          <span class="font-bold font-15">{{ `${orderInfo.date}, ${getTimeString(orderInfo.time)}` }}</span>
        </div>
      </div>
      <div class="m-3 d-flex justify-content-between" v-else>
        <span>{{ orderInfo.time ? 'Date/Time' : 'Date' }}</span>
        <span class="font-bold font-15">{{ orderInfo.time ? `${orderInfo.date}, ${getTimeString(orderInfo.time)}` : orderInfo.date }}</span>
      </div>
      <div class="divider" />
      <div class="m-3 d-flex justify-content-between">
        <span>Location</span>
        <a class="font-bold font-15 text-right" :href="`https://www.google.com/maps?q=${orderInfo.geoPoint.latitude},${orderInfo.geoPoint.longitude}`" target="_blank">{{ orderInfo.address }}</a>
      </div>
      <div v-if="orderInfo.type === ORDER_TYPE_BOX">
        <div class="divider" />
        <span class="mx-3 font-bold">Box Info</span>
        <div class="m-3 d-flex justify-content-between">
          <img :width="$isMobile() ? 100 : 200" :height="$isMobile() ? 100 : 200" :src="orderInfo.details.photoUrl" />
          <div class="d-flex flex-column text-right">
            <span class="font-bold font-15">{{ orderInfo.details.name }}</span>
            <span class="font-15">{{ orderInfo.details.description }}</span>
            <br/>
            <span class="font-15">{{ orderInfo.details.detail }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="m-3 d-flex justify-content-between" v-if="orderInfo.geoPointUnload">
          <span>&nbsp;</span>
          <a class="font-bold font-15 text-right" :href="`https://www.google.com/maps/dir/${orderInfo.geoPoint.latitude},${orderInfo.geoPoint.longitude}/${orderInfo.geoPointUnload.latitude},${orderInfo.geoPoint.longitude}`" target="_blank">{{ orderInfo.addressUnload }}</a>
        </div>
        <div class="m-3 d-flex justify-content-between" v-if="orderInfo.geoPointUnload">
          <span>Miles estimation</span>
          <span class="font-bold font-15">{{ `${getDecimalFormat(orderInfo.miles / 100)} miles` }}</span>
        </div>
        <div class="divider" />
        <div class="m-3 d-flex justify-content-between">
          <span>Hours</span>
          <span class="font-bold font-15">{{ orderInfo.hours }}</span>
        </div>
        <div class="m-3 d-flex justify-content-between">
          <span>Movers</span>
          <span class="font-bold font-15">{{ orderInfo.movers }}</span>
        </div>
        <div class="divider" />
        <div class="m-3 d-flex justify-content-between">
          <span>
            <img :src="assets.truck" :width="30" :height="30" />
            &nbsp;
            Truck
          </span>
          <span class="font-bold font-15">{{ orderInfo.hasTruck === true ? 'YES' : 'NO' }}</span>
        </div>
        <div class="m-3 d-flex justify-content-between">
          <span>
            <img :src="assets.elevator" :width="30" :height="30" />
            &nbsp;
            Elevator
          </span>
          <span class="font-bold font-15">{{ orderInfo.hasElevator === true ? 'YES' : 'NO' }}</span>
        </div>
        <div class="divider" />
        <div class="m-3 d-flex justify-content-between">
          <span>Rooms</span>
          <span class="font-bold font-15">{{ orderInfo.rooms }}</span>
        </div>
        <div class="m-3 d-flex justify-content-between">
          <span>Flight(s) of Stairs</span>
          <span class="font-bold font-15">{{ orderInfo.stairs }}</span>
        </div>
        <div class="divider" />
        <span class="mx-3 font-bold">{{ details.length > 0 ? 'Services' : 'No Services' }}</span>
        <div class="m-3 d-flex justify-content-between" v-for="(item, index) in details" :key="(item, index)">
          <span>
            <img :src="item.photoUrl" :width="30" :height="30" />
            &nbsp;
            {{ item.service }}
          </span>
          <span class="font-bold font-15">{{ item.quantity }}</span>
        </div>
      </div>
      <div class="mt-5 divider" />
      <div class="m-3 d-flex justify-content-between">
        <span class="mt-2">Note</span>
        <span class="font-bold font-15 text-right">{{ orderInfo.note }}</span>
      </div>
      <div class="mt-5 divider" />
      <div class="m-3 d-flex justify-content-between">
        <span class="mt-2">Status</span>
        <span class="font-bold font-15">{{ getStatusString(orderInfo.status) }}</span>
      </div>
      <div class="divider" />
      <div class="m-3 d-flex justify-content-between" v-if="orderInfo.type !== ORDER_TYPE_BOX">
        <span class="mt-2">Used Points</span>
        <span class="font-bold font-15">{{ orderInfo.usedPoints ? getDecimalFormat(orderInfo.usedPoints) : 0 }}</span>
      </div>
      <div class="m-3 d-flex justify-content-between">
        <span class="font-bold">Total</span>
        <span class="font-bold font-20">{{ getPriceFormat(orderInfo.totalPrice / 100, true) }}</span>
      </div>
      <div class="divider" />
    </div>
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/pages/order.scss";
</style>

<script>
import { getPriceFormat, getStatusString, getTimeString, getDecimalFormat, showLoading } from '../../../functions';
import { firestore } from '../../../main';
import { doc, onSnapshot } from 'firebase/firestore';

import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";

import truck from '@/assets/image/truck.png';
import elevator from '@/assets/image/elevator.png';

export default {
  name: 'OrderDetail',
  components: {
    KTFooterExtended
  },
  data() {
    return {
      assets: {
        truck,
        elevator
      },
      details: [],
      orderInfo: null,
      snapOrderInfo: null
    }
  },
  mounted() {
    if (this.$store.state.myInfo) {
      this.getOrderInfoTask();
    } else {
      this.$toast.error('Please login first!')
      this.$router.push('/auth');
    }
  },
  destroyed() {
    if (this.snapOrderInfo !== null) {
      this.snapOrderInfo()
    }
  },
  methods: {
    getPriceFormat,
    getStatusString,
    getTimeString,
    getDecimalFormat,
    getOrderInfoTask() {
      if (this.snapOrderInfo === null) {
        const loader = showLoading(this);
        this.snapOrderInfo = onSnapshot(doc(firestore, 'order', this.$route.params.id), doc => {
          loader.hide();
          this.orderInfo = doc.data();
          if (this.orderInfo.type !== this.ORDER_TYPE_BOX) {
            this.details = [];
            this.orderInfo.details.forEach(info => {
              const serviceInfo = this.$store.state.serviceList.find(element => element.id === info.id);
              this.details.push({
                photoUrl: serviceInfo ? serviceInfo.photoUrl : '',
                service: serviceInfo ? serviceInfo.service : info.service,
                quantity: info.quantity
              });
            });
          }
        });
      }
    }
  }
};
</script>